import { Routes } from '@angular/router';

import { ERoutes } from 'src/app/core/enums/routes.enum';

export const routes: Routes = [
  {
    path: ERoutes.EMPTY,
    loadChildren: () =>
      import('src/app/modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: ERoutes.WIDGETS,
    loadChildren: () =>
      import('src/app/modules/widgets/widgets.module').then(
        (m) => m.WidgetsModule,
      ),
  },
  {
    path: ERoutes.TRIP_PLANNER,
    loadChildren: () =>
      import('src/app/modules/new-trip-planner/new-trip-planner.module').then(
        (m) => m.NewTripPlannerModule,
      ),
  },
  {
    path: ERoutes.ACCOUNT,
    loadChildren: () =>
      import('src/app/modules/user-account/user-account.module').then(
        (m) => m.UserAccountModule,
      ),
  },
  {
    path: ERoutes.CAR_RENTAL,
    loadChildren: () =>
      import('src/app/modules/car-rental/car-rental.module').then(
        (m) => m.CarRentalModule,
      ),
  },
  {
    path: ERoutes.COUNTRIES,
    loadChildren: () =>
      import('src/app/modules/countries/countries.module').then(
        (m) => m.CountriesModule,
      ),
  },
  {
    path: ERoutes.ERROR,
    loadChildren: () =>
      import('src/app/modules/error/error.module').then(
        (m) => m.CountryInfoModule,
      ),
  },
  {
    path: ERoutes.EMPTY,
    loadChildren: () =>
      import('src/app/modules/company-info/company-info.module').then(
        (m) => m.CompanyInfoModule,
      ),
  },
  {
    path: ERoutes.EMPTY,
    loadChildren: () =>
      import('src/app/modules/country-info/country-info.module').then(
        (m) => m.CountryInfoModule,
      ),
  },
  {
    path: ERoutes.UNKNOWN,
    redirectTo: `${ERoutes.ERROR}`,
  },
];
