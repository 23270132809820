@if (mobileMenuOpen) {
    <div class="mobile-menu-container__wrapper" [@menuSlideIn]>
        <ul class="mobile-menu-container__wrapper__list">
            <li class="mobile-menu-container__wrapper__list-item">
                <p>
                    {{ WHY_LITHUANIA.label }}
                    <img ngSrc="assets/svg/ic-arrow-right.svg" width="24" height="24" alt="arrow-right">
                </p>
                <ul>
                    @for (sub of WHY_LITHUANIA.subLinks; track $index) {
                        <li>
                            <a [attr.href]="sub.link">{{ sub.label }}</a>
                        </li>
                    }
                </ul>
            </li>
            <li class="mobile-menu-container__wrapper__list-item">
                <p>
                    {{ REGIONS_LITHUANIA.label }}
                    <img ngSrc="assets/svg/ic-arrow-right.svg" width="24" height="24" alt="arrow-right">
                </p>
                <ul>
                    @for (sub of REGIONS_LITHUANIA.subLinks; track $index) {
                        <li>
                            <a [attr.href]="sub.link">{{ sub.label }}</a>
                        </li>
                    }
                </ul>
            </li>
            <li class="mobile-menu-container__wrapper__list-item">
                <p>
                    {{ WHAT_TO_DO_LITHUANIA.label }}
                    <img ngSrc="assets/svg/ic-arrow-right.svg" width="24" height="24" alt="arrow-right">
                </p>
                <ul>
                    @for (sub of WHAT_TO_DO_LITHUANIA.subLinks; track $index) {
                        <li>
                            <a [attr.href]="sub.link">{{ sub.label }}</a>
                        </li>
                    }
                </ul>
            </li>
            <li class="mobile-menu-container__wrapper__list-item">
                <p>
                    <a href="https://www.lithuania.travel/en/explore-map">
                        Explore map
                        <img ngSrc="assets/svg/ic-arrow-right.svg" width="24" height="24" alt="arrow-right">
                    </a>
                </p>
            </li>
        </ul>
    </div>
}
