import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, Subject, takeUntil } from 'rxjs';

import { IFooterItem } from '../../interfaces/footer';
import {
  COMPANY_MENU,
  DESTINATIONS_MENU,
} from '../../constants/footer-menus.const';
import { ERoutes } from 'src/app/core/enums/routes.enum';
import { EDomainValue } from 'src/app/core/enums/domain.enum';
import { EUrlTarget } from 'src/app/core/enums/url-target.enum';

import { AuthService } from 'src/app/core/services/auth.service';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { DomainTrackingService } from 'src/app/core/services/domain-tracking.service';
import { GoogleAnalyticService } from 'src/app/core/services/google-analytic.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showMobileLinks: boolean = true;

  public readonly today: Date = new Date();
  public readonly eUrlTarget = EUrlTarget;
  public readonly eDomainValue = EDomainValue;
  public readonly companyMenu = COMPANY_MENU;
  public readonly destinationMenu = DESTINATIONS_MENU;

  public domainValue: string = '';
  public canRender: boolean = false;
  public isClientFromIsrael: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(
    protected readonly breakpointService: BreakpointService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private domainTrackingService: DomainTrackingService,
    private readonly localStorageService: LocalStorageService,
    private readonly googleAnalyticService: GoogleAnalyticService,
  ) {}

  ngOnInit() {
    this.checkWhitelabelFooter();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderFooter();
      this.router.events
        .pipe(
          takeUntil(this.destroy$),
          filter((event) => event instanceof NavigationEnd),
        )
        .subscribe(() => {
          this.renderFooter();
        });
    }, 1000);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @param item
   * @private
   * navigation by url in menu item
   */
  async navigateMenuItem(item: IFooterItem): Promise<any> {
    const isInternalLink = item.target !== EUrlTarget.BLANK;
    if (isInternalLink) {
      if (Array.isArray(item.url)) {
        await this.router
          .navigate(item.url)
          .then(() => this.afterNavigateMenuItem(item));
      } else if (typeof item.url === 'string') {
        await this.router
          .navigateByUrl(item.url)
          .then(() => this.afterNavigateMenuItem(item));
      }
    } else {
      window.open(item.url as string, item.target);
    }
  }

  navigateToTripPlanner(): void {
    if (this.router.url === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.router.navigate(['/']).then();
    }
  }

  private renderFooter(): void {
    if (this.breakpointService.isDesktop()) {
      this.canRender = true;
      setTimeout(() => this.checkIfClientIsFromIsrael(), 2_500);
    } else {
      this.canRender = !this.router.url.includes(ERoutes.TRIP_PLANNER);
    }
    this.cdr.markForCheck();
  }

  /**
   * @param item
   * @private
   * here you can add logic what you do after navigation
   */
  private afterNavigateMenuItem(item: IFooterItem): void {
    this.googleAnalyticService.setFooterAnalytic({
      label: item.label,
      userID: this.authService.userId,
    });
  }

  private checkWhitelabelFooter(): void {
    const whitelabel = this.domainTrackingService.whitelabel;

    if (whitelabel) {
      this.domainValue = whitelabel.value;
    }
  }

  private checkIfClientIsFromIsrael(): void {
    const country: string = this.localStorageService.getLocalStorage(
      'rpClientCountry',
      '',
    );

    if (country && country === 'israel') {
      this.isClientFromIsrael = true;
      this.cdr.markForCheck();
    }
  }
}
