import { trigger, animate, transition, style } from '@angular/animations';

export const sideSlideAnimation = trigger('menuSlideIn', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)', opacity: 0 }),
    animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0)', opacity: 1 }),
    animate('200ms', style({ transform: 'translateX(-100%)', opacity: 0 })),
  ]),
]);
