import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { DeviceDetectorService } from 'ngx-device-detector';

import { Observable, Subscription } from 'rxjs';

import { Trip } from '../../classes/trip';
import { ERoutes } from 'src/app/core/enums/routes.enum';
import { ESvgIcons } from 'src/app/core/enums/svg-icons';
import { EDomainValue } from 'src/app/core/enums/domain.enum';
import { UserProfile } from 'src/app/core/interfaces/user-profile';

import { AuthAction } from '../../classes/auth-action';

import { AuthService } from 'src/app/core/services/auth.service';
import { TripsService } from 'src/app/core/services/trips.service';
import { CloudinaryService } from 'src/app/core/services/cloudinary.service';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { ScrollToIdService } from 'src/app/core/services/scroll-to-id.service';
import { HeaderStateService } from 'src/app/core/services/header-state.service';
import { DomainTrackingService } from 'src/app/core/services/domain-tracking.service';
import { GoogleAnalyticService } from 'src/app/core/services/google-analytic.service';
import { FavoritesProviderService } from '../../../core/services/favorites-provider.service';
import { TripWizardStateService } from '../../components/trip-wizard/trip-wizard-state.service';

import { PlanningFormSheetComponent } from '../../sheets/planning-form-sheet/planning-form-sheet.component';

import { environment } from 'src/environments/environment';

import moment from 'moment';

export interface IPreviewTripConfig {
  showLogo: boolean;
  startInLocation: string;
  endInLocation: string;
  activePlan: string;
  duration: string;
  travelers: number;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
  host: { ngSkipHydration: 'true' },
})
export class HeaderComponent extends AuthAction implements OnInit, OnDestroy {
  public readonly headerStateService = inject(HeaderStateService);
  public readonly eDomainValue = EDomainValue;
  public readonly eSvgIcons = ESvgIcons;
  public readonly eRoutes = ERoutes;
  public readonly mainLogo: string =
    environment.cdnUrl + 'app/images/routeperfect_logo.png';
  public readonly mobileLogo: string =
    environment.cdnUrl + 'app/images/logo-og.png';

  public mobileMenuOpen = false;
  public isAccountPage = false;
  public userProfile: UserProfile;
  public loggedMenuItems = [];
  public isLogged: boolean;
  public trip: Trip;
  public previewTripConfig: IPreviewTripConfig = {
    showLogo: true,
    startInLocation: '',
    endInLocation: '',
    activePlan: '',
    duration: '',
    travelers: 0,
  };
  public savedTrips$: Observable<number>;

  public domainValue = '';
  public logSubscription: Subscription;

  private routeSubscription: Subscription;
  private tripsCountSubs: Subscription;

  #cdRef = inject(ChangeDetectorRef);
  #tripWizardStateService = inject(TripWizardStateService);

  constructor(
    public authService: AuthService,
    public router: Router,
    public device: DeviceDetectorService,
    private tripsService: TripsService,
    private scrollToIdService: ScrollToIdService,
    private cloudinaryService: CloudinaryService,
    private domainTrackingService: DomainTrackingService,
    private bottomSheet: MatBottomSheet,
    protected googleAnalyticService: GoogleAnalyticService,
    protected readonly breakpointService: BreakpointService,
    private favoritesProviderService: FavoritesProviderService,
  ) {
    super();
    this.mainLogo = this.cloudinaryService.getCloudImage(this.mainLogo);
    this.#tripWizardStateService.currentTripState
      .pipe(takeUntilDestroyed())
      .subscribe((trip) => {
        const {
          route,
          isSpecifyDates,
          tripStartDate,
          tripEndDate,
          numDays,
          rooms,
        } = trip;
        if (route && route.length) {
          const firstRoute = route[0];
          const lastRoute = route[route.length - 1];
          const dateFormat = 'MMM D, YYYY';
          const parseLocationName = (name: string) => {
            if (!name.includes(',')) return name;
            return name.split(',')[0];
          };

          this.previewTripConfig = {
            ...this.previewTripConfig,
            showLogo: false,
            startInLocation: parseLocationName(firstRoute.name),
            endInLocation: parseLocationName(lastRoute.name),
            activePlan: `${parseLocationName(firstRoute.name)} - ${parseLocationName(lastRoute.name)} (${numDays} days)`,
            travelers: rooms.adults + rooms.children,
          };

          if (!isSpecifyDates) {
            this.previewTripConfig.duration = `${numDays} days`;
          } else {
            this.previewTripConfig.duration = `${moment(tripStartDate).format(dateFormat)} - ${moment(tripEndDate).format(dateFormat)}`;
          }

          this.#cdRef.markForCheck();
        }
      });
  }

  ngOnInit(): void {
    this.checkWhitelabelHeader();
    this.userProfile = this.authService.userProfile;
    this.savedTrips$ = this.tripsService.savedTripsCount$;
    this.tripsService.updateTripCount();
    this.favoritesProviderService.getAllFavorites().subscribe();

    this.logSubscription = this.authService.isLogged.subscribe((isLogged) => {
      this.isLogged = isLogged;
      this.loggedMenuItems = [];

      if (this.isLogged && this.authService.userProfile) {
        this.userProfile = this.authService.userProfile;
        this.loggedMenuItems = [
          {
            route: 'account/my-wish-list',
            title: 'My Wishlist',
          },
          {
            route: 'account/settings',
            title: 'Personal settings',
          },
        ];
      }

      this.#cdRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    const subscriptionsArr: Subscription[] = [
      this.logSubscription,
      this.routeSubscription,
      this.tripsCountSubs,
    ];

    subscriptionsArr.forEach((subscription: Subscription) => {
      if (typeof subscription !== 'undefined' && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  logStatus(isLogged: boolean, route = ERoutes.EMPTY): void {
    this.authService.logStatus(isLogged);
    if (this.router.url.includes(ERoutes.ACCOUNT)) {
      this.router
        .navigateByUrl(this.domainTrackingService.getPostLogoutRoute())
        .then();
    }

    if (!isLogged) {
      this.isLogged = isLogged;
      this.loggedMenuItems = [];
    }
  }

  toggleBtnHandler(): void {
    if (this.headerStateService.backBtnPresent()) {
      this.headerStateService.onBackBtnClick();
      document.documentElement.classList.remove('scroll-block');
      this.scrollToIdService.enableScrollEvent();
      return;
    }

    this.toggleMobileMenu();
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;

    if (this.bottomSheet._openedBottomSheetRef) {
      this.bottomSheet._openedBottomSheetRef.dismiss();
    }

    if (this.mobileMenuOpen) {
      document.documentElement.classList.add('scroll-block');
      this.scrollToIdService.disableScrollEvent();
      return;
    }

    document.documentElement.classList.remove('scroll-block');
    this.scrollToIdService.enableScrollEvent();
  }

  navigateTo(routes: any): void {
    this.router.navigate(routes);
    this.mobileMenuOpen = false;
  }

  goToTripPlannerPage(): void {
    this.setHeaderAnalytic('plan a trip');
    this.router.navigate(['/trip-planner']).then();
  }

  setRouteAnalytic(route: string) {
    if (route === 'account/settings') {
      this.setHeaderAnalytic('pesonal settings');
    }
  }

  openPlanningFormDialog(): void {
    this.mobileMenuOpen = false;
    this.bottomSheet.open(PlanningFormSheetComponent, {
      hasBackdrop: false,
      closeOnNavigation: true,
      panelClass: 'custom-bottom-sheet',
    });
  }

  async backToPrevRoute(): Promise<void> {
    await this.router.navigate([ERoutes.EMPTY]);
  }

  private checkWhitelabelHeader(): void {
    const whitelabel = this.domainTrackingService.whitelabel;

    if (whitelabel) {
      this.domainValue = whitelabel.value;
    }
  }
}
