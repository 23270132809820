import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { filter, first, Subject, takeUntil } from 'rxjs';

import { Swiper } from 'swiper';
import {
  Autoplay,
  Navigation,
  Pagination,
  Thumbs,
  Virtual,
} from 'swiper/modules';

import { HomeModule } from './modules/home/home.module';
import { DialogModule } from './shared/dialogs/dialog.module';
import { LandingModule } from './shared/landing/landing.module';
import { AuthDialogModule } from './shared/dialogs/auth-dialog/auth-dialog.module';

import { ERoutes } from './core/enums/routes.enum';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';

import { ToastService } from './core/services/toast.service';
import { AuthService } from './core/services/auth.service';
import { RadarService } from './core/services/radar.service';
import { TripsService } from './core/services/trips.service';
import { NewAuthService } from './core/services/new-auth.service';
import { SsrHelperService } from './core/services/ssr-helper.service';
import { BreakpointService } from './core/services/breakpoint.service';
import { ApiSettingsService } from './core/services/api-settings.service';
import { RoutingStateService } from './core/services/routing-state.service';
import { PopupTrackingService } from './core/services/popup-tracking.service';
import { DomainTrackingService } from './core/services/domain-tracking.service';
import { GoogleAnalyticService } from './core/services/google-analytic.service';
import { SessionActivityService } from './core/services/session-activity.service';
import { TripWizardStateService } from './shared/components/trip-wizard/trip-wizard-state.service';

import { TripWizardComponent } from './shared/components/trip-wizard/trip-wizard.component';

Swiper.use([Navigation, Autoplay, Pagination, Thumbs, Virtual]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    RouterOutlet,
    LandingModule,
    HomeModule,
    DialogModule,
    AuthDialogModule,
    TripWizardComponent,
  ],
  providers: [DomainTrackingService, ApiSettingsService],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public initialLoad: boolean = true;
  public isWidgetView: boolean = false;
  public showHeaderForm = signal(false);

  private isLogged: boolean = true;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    public ssrHelperService: SsrHelperService,
    private authService: AuthService,
    private toastService: ToastService,
    private newAuthService: NewAuthService,
    private socialAuthService: SocialAuthService,
    private apiSettingsService: ApiSettingsService,
    private routingStateService: RoutingStateService,
    private domainTrackingService: DomainTrackingService,
    private popupTrackingService: PopupTrackingService,
    private readonly cdr: ChangeDetectorRef,
    private readonly tripsService: TripsService,
    private readonly radarService: RadarService,
    private readonly googleAnalyticService: GoogleAnalyticService,
    private readonly applicationRef: ApplicationRef,
    private readonly tripWizardStateService: TripWizardStateService,
    protected readonly breakpointService: BreakpointService,
    protected readonly sessionActivityService: SessionActivityService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (this.ssrHelperService.userAgent) {
      this.document.defaultView.window.addEventListener('resize', () => {
        this.breakpointService.updateWindowWidth();
      });
      this.isWidgetView =
        this.document.defaultView.window.location.href.includes(
          ERoutes.WIDGETS,
        );
    }
    if (!this.isWidgetView) {
      this.domainTrackingService.checkIfIsWhitelabel();
      this.apiSettingsService.isLoggedIn().subscribe();
      this.apiSettingsService.isUserLoggedIn
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.isLogged = res;
        });
    }
    this.disableZoomForMobile();
  }

  get isError(): boolean {
    return this.router.url.includes('/error');
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((neEvent: NavigationEnd) => {
        this.ssrHelperService.scrollToTop();
        this.googleAnalyticService.setUniqUserIdAnalytic({
          userID: this.authService.userId,
          url: neEvent.url,
        });

        if (!this.initialLoad) {
          this.ssrHelperService.setZAEvent();
        }

        this.initialLoad = false;
        this.checkIfTripWizardNeedToHeader();
        const prevUrl = neEvent.urlAfterRedirects;
        this.routingStateService.prevRouteSubject.next(prevUrl);
      });

    this.tripWizardStateService.currentTripState
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.checkIfTripWizardNeedToHeader());
  }

  ngAfterViewInit() {
    console.log(
      '%cRoutePerfect %c v.3.179.326 %c',
      'color: black; font-weight: bold; background-color: #ffc10e; border-radius: 4px 0 0 4px; padding: 2px 1px 2px 5px',
      'color: black; font-weight: normal; background-color: #ffc10e; border-radius: 0 4px 4px 0; padding: 2px 5px 2px 1px',
      'font-weight: normal',
    );

    if (this.ssrHelperService.userAgent) {
      this.breakpointService.updateWindowWidth();
      this.radarService.initializeRadar();
      this.checkIfIsNeedReZoomOverlays();
      this.handleSocialAuthState();
      this.onUserDetectHandler();

      this.applicationRef.isStable
        .pipe(first((isStable) => isStable))
        .subscribe(() => {
          setTimeout(() => {
            this.sessionActivityService.init();
            this.popupTrackingService.startTracking();
            // TODO Need to leave this function for a month, it deletes old service workers for clients that were registered previously.
            this.ssrHelperService.clearOldClientServiceWorker().then();
          }, 25);
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.sessionActivityService.unsubscribeAll();
  }

  private isRootPath(): boolean {
    const currentUrl = this.router.url;
    return currentUrl === ERoutes.SLASH;
  }

  private isPanningPath(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes(ERoutes.TRIP_PLANNER);
  }

  private onUserDetectHandler(): void {
    this.authService.isLogged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdr.detectChanges());
  }

  private disableZoomForMobile(): void {
    if (this.ssrHelperService.userAgent) {
      this.document.defaultView.window.addEventListener(
        'touchmove',
        (ev: TouchEvent) => {
          if (ev.touches && ev.touches.length > 1) {
            ev.preventDefault();
          }
        },
        { passive: false },
      );
    }
  }

  private handleSocialAuthState(): void {
    if (!this.isWidgetView) {
      this.socialAuthService.authState
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (user: SocialUser) => {
            this.apiSettingsService.isLoggedIn().subscribe(() => {
              if (!this.isLogged && user) {
                switch (user.provider) {
                  case GoogleLoginProvider.PROVIDER_ID:
                    this.newAuthService.googleLogin(user).subscribe(() => {
                      this.newAuthService.socialLoginDone.next();
                      this.apiSettingsService.isUserLoggedIn.next(true);
                      this.tripsService.updateTripCount();
                      this.cdr.detectChanges();
                    });
                    break;
                }
              }
            });
          },
          error: () =>
            this.toastService.showErrorSnack(
              'Oops! Something went wrong, please try again later!',
            ),
        });
    }
  }

  private checkIfIsNeedReZoomOverlays(): void {
    const browser: string = this.ssrHelperService.userBrowser.toLowerCase();
    if (browser && !browser.includes('safari')) {
      document.body.classList.add('re-zoom-overlays');
    }
  }

  private checkIfTripWizardNeedToHeader(): void {
    switch (true) {
      case this.isRootPath():
        this.showHeaderForm.set(false);
        break;
      case this.isPanningPath():
        const { route } = this.tripWizardStateService.currentTripStateValue;
        this.showHeaderForm.set(!!route.length);
        break;
      default:
        this.showHeaderForm.set(true);
        break;
    }
  }
}
