@if (!isWidgetView) {
    <app-header>
        @if (showHeaderForm()) {
            <app-trip-wizard mode="header"></app-trip-wizard>
        }
    </app-header>
    <main class="content-box" [class.content-box--error]="isError">
        <router-outlet></router-outlet>
    </main>
    <app-footer [showMobileLinks]="!isError"></app-footer>
} @else {
    <router-outlet></router-outlet>
}
