import { Component, Input, ViewEncapsulation } from '@angular/core';

import { UserProfile } from '../../../../core/interfaces/user-profile';

import { HeaderComponent } from '../../header/header.component';
import { ERoutes } from 'src/app/core/enums/routes.enum';

@Component({
    selector: 'app-header-lt',
    templateUrl: './header-lt.component.html',
    styleUrls: [
        './header-lt.component.scss',
        '../../header/header.component.scss',
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HeaderLtComponent extends HeaderComponent {
  @Input() userProfile: UserProfile;
  @Input() isLogged: boolean = false;
  @Input() mobileMenuOpen: boolean = false;

  public readonly breakpoints = this.device;
  readonly WHY_LITHUANIA = {
    label: 'Why Lithuania',
    url: '',
    subLinks: [
      {
        icon: 'ic-waves.svg',
        label: 'By the Baltic Sea',
        link: 'https://www.lithuania.travel/en/why-lithuania/by-the-baltic-sea',
      },
      {
        icon: 'ic-sunny-s.svg',
        label: 'The country of 4 seasons',
        link: 'https://www.lithuania.travel/en/why-lithuania/country-of-4-seasons',
      },
      {
        icon: 'ic-texture.svg',
        label: 'Culture and heritage',
        link: 'https://www.lithuania.travel/en/why-lithuania/culture-and-heritage',
      },
      {
        icon: 'ic-lightblub.svg',
        label: 'Ideas and innovations',
        link: 'https://www.lithuania.travel/en/why-lithuania/ideas-and-innovations',
      },
      {
        icon: 'ic-check-c.svg',
        label: 'Where to visit?',
        link: 'https://www.lithuania.travel/en/where-to-visit',
      },
    ],
  };

  readonly REGIONS_LITHUANIA = {
    label: 'Where to go?',
    url: '',
    subLinks: [
      {
        icon: 'ic-city.svg',
        label: 'Major cities',
        link: 'https://www.lithuania.travel/en/where-to-visit/major-cities',
      },
      {
        icon: 'ic-nature.svg',
        label: 'Nature',
        link: 'https://www.lithuania.travel/en/where-to-visit/nature',
      },
      {
        icon: 'ic-diamond.svg',
        label: 'Undiscovered gems',
        link: 'https://www.lithuania.travel/en/where-to-visit/undiscovered-gems',
      },
      {
        icon: 'ic-sunny-s.svg',
        label: 'Seasonal highlights',
        link: 'https://www.lithuania.travel/en/where-to-visit/seasonal-highlights',
      },
    ],
  };

  readonly WHAT_TO_DO_LITHUANIA = {
    label: 'What to do?',
    url: '',
    subLinks: [
      {
        icon: 'ic-landscape.svg',
        label: 'Active recreation',
        link: 'https://www.lithuania.travel/en/what-to-do/active-recreation',
      },
      {
        icon: 'ic-fastfood.svg',
        label: 'Food & Drink',
        link: 'https://www.lithuania.travel/en/what-to-do/food-and-drink',
      },
      {
        icon: 'ic-image.svg',
        label: 'History, culture, art',
        link: 'https://www.lithuania.travel/en/what-to-do/history-culture-art',
      },
      {
        icon: 'ic-park.svg',
        label: 'Ecotourism',
        link: 'https://www.lithuania.travel/en/what-to-do/ecotourism',
      },
    ],
  };

  public activeTabIndex: number = -1;
  public activeTabLinks = [];

  setActiveTabIndex(index: number): void {
    if (this.activeTabIndex === index) {
      this.activeTabIndex = -1;
      return;
    }

    if (this.activeTabIndex === -1) {
      this.setActiveTab(index);
    } else {
      this.activeTabIndex = -1;
      setTimeout(() => this.setActiveTab(index), 400);
    }
  }

  setActiveTab(activeTabIndex: number): void {
    this.activeTabIndex = activeTabIndex;

    switch (activeTabIndex) {
      case 0:
        this.activeTabLinks = this.WHY_LITHUANIA.subLinks;
        break;
      case 1:
        this.activeTabLinks = this.REGIONS_LITHUANIA.subLinks;
        break;
      case 2:
        this.activeTabLinks = this.WHAT_TO_DO_LITHUANIA.subLinks;
        break;
    }
  }

  logStatus(isLogged: boolean): void {
    super.logStatus(isLogged, ERoutes.TRIP_PLANNER);
  }
}
