import { HttpRequest, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable } from 'rxjs';

import { SsrHelperService } from '../services/ssr-helper.service';
import { ApiSettingsService } from '../services/api-settings.service';

const skipUrlArr: string[] = [
  'lookup.hotels',
  'trip-planner/ai',
  'getActiveCampaign',
];

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const ssrHelperService = inject(SsrHelperService);
  const apiSettingsService = inject(ApiSettingsService);

  if (!ssrHelperService.userAgent && shouldSkipRequest(req.url)) {
    return new Observable();
  }

  if (skipUrlArr.some((skipUrl) => req.url.includes(skipUrl))) {
    return next(req);
  }

  return next(addAuthenticationToken(req, apiSettingsService.userSessionId));
};

function addAuthenticationToken(
  request: HttpRequest<any>,
  sessionId: string,
): HttpRequest<any> {
  return request.clone({
    setHeaders: {
      sessionId: sessionId,
    },
  });
}

function shouldSkipRequest(url: string): boolean {
  const invalidExtensions = [
    '.js',
    '_js',
    '.ts',
    '_ts',
    '.map',
    '.txt',
    '.ico',
    'destinations/assets',
  ];
  return (
    url.includes('api.') && invalidExtensions.some((ext) => url.includes(ext))
  );
}
