<header class="header-lt">
    <div class="header-lt__wrapper">
        <nav class="header-lt__nav">
            <div class="site-menu__section hide-for-medium">
                <button class="hamburger" [class.hamburger--open]="mobileMenuOpen" (click)="toggleMobileMenu()">
                    <span class="hamburger__bar"></span>
                    <span class="hamburger__bar"></span>
                    <span class="hamburger__bar"></span>
                </button>
            </div>
            <div class="logo-box">
                <a href="https://www.lithuania.travel">
                    <img ngSrc="assets/lithuanian/lt-logo.svg" width="129" height="36" alt="logo">
                </a>
            </div>
            <ul class="links-group">
                <li class="links-group__item" [class.links-group__item--active]="activeTabIndex === 0"
                    (click)="setActiveTabIndex(0)">
                    Why Lithuania
                    <img ngSrc="assets/svg/icon-arrow_down.svg" width="20" height="20" alt="arrow">
                </li>
                <li class="links-group__item" [class.links-group__item--active]="activeTabIndex === 1"
                    (click)="setActiveTabIndex(1)">
                    Regions
                    <img ngSrc="assets/svg/icon-arrow_down.svg" width="20" height="20" alt="arrow">
                </li>
                <li class="links-group__item" [class.links-group__item--active]="activeTabIndex === 2"
                    (click)="setActiveTabIndex(2)">
                    What to do?
                    <img ngSrc="assets/svg/icon-arrow_down.svg" width="20" height="20" alt="arrow">
                </li>
                <li class="links-group__item">
                    <a href="https://www.lithuania.travel/en/explore-map">Explore map</a>
                </li>
            </ul>
            <div class="auth-block">
                @if (isLogged) {
                    @if (userProfile; as profile) {
                        @if (!breakpoints.isMobile()) {
                            <div class="site-menu__user__placeholder">
                                {{ profile?.name?.charAt(0) }}
                            </div>
                            <div class="site-menu__user__menu show-for-medium">
                                <span class="site-menu__user__menu__name">Hi {{ profile?.name | getName }}</span>
                            </div>
                            <ul class="site-menu__user__dropdown show-for-medium">
                                @for (loggedMenuItem of loggedMenuItems; track $index) {
                                    <li>
                                        <a [routerLink]="[loggedMenuItem.route]"
                                           [routerLinkActiveOptions]="{exact: true}"
                                           routerLinkActive="active" class="site-menu__user__link">
                                            <span>{{ loggedMenuItem.title }}</span>
                                        </a>
                                    </li>
                                }
                                <li>
                                    <button type="button" class="site-menu__user__link site-menu__user__link--logout"
                                            (click)="logStatus(false)">
                                        Sign out
                                    </button>
                                </li>
                            </ul>
                        } @else {
                            @if (isAccountPage) {
                                <div class="site-menu__mobile site-menu__mobile--close" (click)="backToPrevRoute()">
                                    <img ngSrc="assets/svg/ic_close_thin.svg" width="24" height="24" alt="">
                                </div>
                            } @else {
                                <div (click)="navigateTo(['account', 'my-wish-list'])"
                                     class="site-menu__user__placeholder">
                                    {{ profile?.name?.charAt(0) }}
                                </div>
                            }
                        }
                    }
                } @else {
                    <div class="auth-block__desktop-btn">
                        <button class="btn-default login" (click)="openAuthDialog()">Sign in</button>
                        <button class="btn-default signup" (click)="openSignUpAuthDialog()">
                            {{ 'ACTIONS.SIGN_UP' | translate }}
                        </button>
                    </div>

                    <div class="auth-block__mobile-btn" (click)="openAuthDialog()">
                        <img ngSrc="assets/svg/profile.svg" width="24" height="24" alt="profile">
                    </div>
                }
            </div>

        </nav>
        <div class="header-lt__sub-box" [class.header-lt__sub-box--opened]="activeTabIndex !== -1">
            <ul>
                @for (tab of activeTabLinks; track $index) {
                    <li>
                        <a [attr.href]="tab.link" target="_self">
                            <img [ngSrc]="'assets/lithuanian/' + tab.icon" width="24" height="24" alt="icon">
                            <p class="header-lt__text">
                                {{ tab.label }}
                                <img ngSrc="assets/svg/ic-arrow-right.svg" width="24" height="24" alt="arrow-right">
                            </p>
                        </a>
                    </li>
                }
            </ul>
        </div>
    </div>
</header>

<app-mobile-menu-lt
        [mobileMenuOpen]="mobileMenuOpen"
        (handleMobileMenu)="toggleMobileMenu()"
></app-mobile-menu-lt>
