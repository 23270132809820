import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

const supportUrls: string[] = [`${environment.apiUrl}info`];
const noSupportUrls: string[] = [
  'https://test-api.routeperfect.com/api/flights/suggested',
];

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);

  if (noSupportUrls.some((url) => req.url.includes(url))) {
    return next(req);
  }

  if (supportUrls.some((url) => req.url.includes(url))) {
    return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401 && error.status >= 400) {
          router.navigate(['/error'], {
            queryParams: { code: error.status },
          });
        }
        return throwError(() => error);
      }),
    );
  }

  return next(req);
};
