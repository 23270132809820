import { ApplicationRef, inject, Injectable } from '@angular/core';

import { first, fromEvent, Subject, throttleTime } from 'rxjs';

import { SsrHelperService } from './ssr-helper.service';

@Injectable({
  providedIn: 'root',
})
export class SessionActivityService {
  private readonly CHECK_INTERVAL = 60000;
  private readonly MAX_IDLE_TIME = 30 * 60 * 1000;

  private lastInteractionTime: number = Date.now();
  private stop$ = new Subject<void>();
  private intervalId: any;
  private alertShown = false;

  #applicationRef = inject(ApplicationRef);
  #ssrHelperService = inject(SsrHelperService);

  public init(): void {
    this.#applicationRef.isStable
      .pipe(first((isStable) => isStable))
      .subscribe(() => {
        setTimeout(() => {
          this.trackUserActivity();
          this.startIdleCheck();
          this.updateLastInteractionTime();
        }, 50);
      });
  }

  public unsubscribeAll(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.stop$.next();
    this.stop$.complete();

    if (this.#ssrHelperService.userAgent) {
      const events = ['mousemove', 'scroll', 'click'];
      events.forEach((event) =>
        window.removeEventListener(event, () =>
          this.updateLastInteractionTime(),
        ),
      );
    }
  }

  private updateLastInteractionTime(): void {
    this.lastInteractionTime = Date.now();
    localStorage.setItem(
      'lastInteractionTime',
      this.lastInteractionTime.toString(),
    );
  }

  private trackUserActivity(): void {
    if (this.#ssrHelperService.userAgent) {
      const events = ['mousemove', 'scroll', 'click'];
      events.forEach((event) => {
        fromEvent(window, event)
          .pipe(throttleTime(10_000))
          .subscribe(() => this.updateLastInteractionTime());
      });
    }
  }

  private startIdleCheck(): void {
    this.intervalId = setInterval(
      () => this.checkIdleTime(),
      this.CHECK_INTERVAL,
    );
  }

  private checkIdleTime(): void {
    const now = Date.now();
    if (
      !this.alertShown &&
      now - this.lastInteractionTime > this.MAX_IDLE_TIME
    ) {
      this.alertShown = true;
      alert('The page is being updated due to long inactivity.');
      location.reload();
    }
  }
}
