<footer class="footer-lt">
  <div class="footer-lt__wrapper">
    <div class="footer-lt__links">
      <details class="links-group" [open]="!breakpoints.isMobile()">
        <summary>
          <p class="links-group__title">
            <a href="https://www.lithuania.travel/en/why-lithuania">Discover Lithuania</a>
          </p>
        </summary>

        <ul class="links-group__list">
          <li>
            <a href="https://www.lithuania.travel/en/why-lithuania/by-the-baltic-sea">By the Baltic Sea</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/why-lithuania/country-of-4-seasons">Country of 4 seasons</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/why-lithuania/culture-and-heritage">Culture and heritage</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/why-lithuania/ideas-and-innovations">Ideas and innovations</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/where-to-visit">Where to visit?</a>
          </li>
        </ul>
      </details>
      <details class="links-group" [open]="!breakpoints.isMobile()">
        <summary>
          <p class="links-group__title">
            <a href="https://www.lithuania.travel/en/where-to-visit/regions">Where to go?</a>
          </p>
        </summary>

        <ul class="links-group__list">
          <li>
            <a href="https://www.lithuania.travel/en/where-to-visit/major-cities">Major cities</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/where-to-visit/nature">Nature</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/where-to-visit/undiscovered-gems">Undiscovered gems</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/where-to-visit/seasonal-highlights">Seasonal highlights</a>
          </li>
        </ul>
      </details>
      <details class="links-group" [open]="!breakpoints.isMobile()">
        <summary>
          <p class="links-group__title">
            <a href="https://www.lithuania.travel/en/what-to-do">What to do?</a>
          </p>
        </summary>

        <ul class="links-group__list">
          <li>
            <a href="https://www.lithuania.travel/en/what-to-do/active-recreation">Active recreation</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/what-to-do/food-and-drink">Food & Drink</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/what-to-do/history-culture-art">History, culture, art</a>
          </li>
          <li>
            <a href="https://www.lithuania.travel/en/what-to-do/ecotourism">Ecotourism</a>
          </li>
        </ul>
      </details>
    </div>
    <div class="footer-lt__companies">
      <div class="footer-lt__companies__logos">
        <a href="https://lietuva.lt">
          <img ngSrc="assets/icon/co-create.svg" width="108" height="56" alt="">
        </a>
        <a href="https://www.tripadvisor.com">
          <img ngSrc="assets/icon/tripadvisor-w.svg" width="156" height="24" alt="">
        </a>
      </div>
      <div class="footer-lt__companies__powered">
        <p>Powered by</p>
        <a href="https://www.routeperfect.com" class="routeperfect-logo">
          <img [ngSrc]="routePerfectLogo" width="122" height="32" alt="routePerfect logo">
        </a>
      </div>
      <ul class="footer-lt__companies__social">
        <li>
          <a href="https://www.facebook.com/LithuaniaTravel.global">
            <img ngSrc="assets/svg/ic-facebook-b.svg" width="20" height="20" alt="facebook">
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/lithuania-travel">
            <img ngSrc="assets/svg/ic-linkedin-b.svg" width="20" height="20" alt="linkedin">
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCeCdR0hdtvaauhwJm2LRuqQ">
            <img ngSrc="assets/svg/ic-youtube-b.svg" width="20" height="20" alt="youtube">
          </a>
        </li>
      </ul>
    </div>
    <div class="footer-lt__bottom">
      <p>All Rights Reserved © Lithuania Travel</p>
      <ul>
        <li>
          <a href="https://lithuania.travel/en/static-page/faq">FAQ</a>
        </li>
        <li>
          <a href="https://lithuania.travel/en/static-page/contact-form">Contact form</a>
        </li>
        <li>
          <a href="https://lithuania.travel/en/publications">Publications</a>
        </li>
        <li>
          <a href="https://lithuania.travel/en/static-page/privacy-policy-1">Privacy policy</a>
        </li>
      </ul>
    </div>
  </div>
</footer>
