import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { IPreviewTripConfig } from '../header.component';
import { ERoutes } from 'src/app/core/enums/routes.enum';
import { EUrlTarget } from 'src/app/core/enums/url-target.enum';
import { sideSlideAnimation } from 'src/app/shared/animations/side-slide.animation';

import {
  GoogleAnalyticService,
  HeaderDesktopEvent,
} from 'src/app/core/services/google-analytic.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CityProviders } from 'src/app/core/utils/city-services';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { SchedulerBlockNavigationService } from 'src/app/core/services/scheduler-block-navigation.service';

import { PlanningFormSheetComponent } from '../../../sheets/planning-form-sheet/planning-form-sheet.component';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [sideSlideAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MobileMenuComponent {
  @Input() mobileMenuOpen: boolean;
  @Input() previewTripConfig: IPreviewTripConfig | null = null;
  @Output() handleMobileMenu: EventEmitter<boolean> = new EventEmitter();

  readonly eRoutes = ERoutes;
  readonly eUrlTarget = EUrlTarget;
  readonly eCityProvider = CityProviders;
  today: Date = new Date();
  isClientFromIsrael: boolean = false;
  mainMenuItems = [
    {
      route: './',
      title: 'Home',
      target: EUrlTarget.SELF,
      icon: 'ic-home.svg',
    },
    {
      route: './trip-planner',
      title: 'Plan a trip',
      target: EUrlTarget.SELF,
      icon: 'ic-map.svg',
    },
  ];
  additionalMenuItems = [
    { route: './countries', title: 'Countries', target: EUrlTarget.SELF },
    {
      route: 'https://www.routeperfect.com/blog',
      title: 'Blog',
      target: EUrlTarget.BLANK,
    },
    { route: './about-us', title: 'About', target: EUrlTarget.SELF },
    {
      route: `/${ERoutes.TRIP_TESTIMONIALS}`,
      title: 'Testimonials',
      target: EUrlTarget.SELF,
    },
  ];

  readonly #matBottomSheet = inject(MatBottomSheet);

  constructor(
    private router: Router,
    protected authService: AuthService,
    protected googleAnalyticService: GoogleAnalyticService,
    protected schedulerBlockNavigationService: SchedulerBlockNavigationService,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.checkIfClientIsFromIsrael();
  }

  setRouteAnalytic(title: string) {
    if (title === 'Plan a trip') {
      this.setHeaderMobileAnalytic('plan a trip');
    } else {
      this.setHeaderMobileAnalytic(title as HeaderDesktopEvent);
    }
  }

  setHeaderMobileAnalytic(event: HeaderDesktopEvent): void {
    this.googleAnalyticService.setHeaderMobileAnalytic({
      header_click: event,
      userID: this.authService.userId,
    });
  }

  redirectTo(path: string, target: EUrlTarget, title: string, ev: Event): void {
    ev.preventDefault();

    this.setRouteAnalytic(title);

    if (title === this.mainMenuItems[1].title) {
      this.closeMobileMenu();
      this.goToPlanningForm();
      return;
    }

    if (target === EUrlTarget.BLANK) {
      window.open(path, EUrlTarget.BLANK);
      return;
    }

    this.router.navigate([path]).then(() => this.closeMobileMenu());
  }

  goToPlannerService(routes: ERoutes[], cityProvider: CityProviders): void {
    this.schedulerBlockNavigationService.scheduleNavigation(0, cityProvider);
    this.router.navigate(routes).then(() => this.closeMobileMenu());
  }

  closeMobileMenu(): void {
    this.mobileMenuOpen = false;
    this.handleMobileMenu.emit(this.mobileMenuOpen);
  }

  goToPlanningForm(): void {
    this.#matBottomSheet.open(PlanningFormSheetComponent, {
      hasBackdrop: false,
      closeOnNavigation: true,
      panelClass: 'custom-bottom-sheet',
    });
  }

  private checkIfClientIsFromIsrael(): void {
    const country: string = this.localStorageService.getLocalStorage(
      'rpClientCountry',
      '',
    );

    if (country && country === 'israel') {
      this.isClientFromIsrael = true;
    }
  }
}
