import { inject, Injectable, NgZone } from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthAction } from '../../shared/classes/auth-action';

@Injectable({
  providedIn: 'root',
})
export class PopupTrackingService extends AuthAction {
  private observer: MutationObserver | null = null;
  private clickSubscription: Subscription | null = null;
  private popupElement: HTMLElement | null = null;
  private shadowRoot: ShadowRoot | null = null;

  private ngZone = inject(NgZone);

  startTracking(): void {
    this.checkForPopup();
    this.observer = new MutationObserver(() => this.checkForPopup());
    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  private checkForPopup(): void {
    const popupContainer = document.querySelector('sp-popups') as HTMLElement;
    if (popupContainer) {
      this.shadowRoot = popupContainer.shadowRoot;
      if (this.shadowRoot) {
        this.popupElement = this.shadowRoot.querySelector(
          '.sp-main-popup',
        ) as HTMLElement;
        if (this.popupElement) {
          this.attachClickListeners(this.popupElement);
          this.observePopupClose();
        }
      }
    }
  }

  private attachClickListeners(popup: HTMLElement): void {
    this.detachClickListeners();
    this.ngZone.runOutsideAngular(() => {
      this.addPointerToSpecialFonts(popup);
      this.clickSubscription = fromEvent(popup, 'click')
        .pipe(
          filter((event: Event) => {
            const target = event.target as HTMLElement;
            return this.isTrackedButton(target);
          }),
        )
        .subscribe((event) => {
          const target = event.target as HTMLElement;
          const actionType = this.getAuthActionType(target);
          const closeEl = popup.querySelector('.sp-popup-close') as HTMLElement;
          if (actionType === 'signIn') {
            this.openAuthDialog();
          } else {
            this.openSignUpAuthDialog();
          }
          if (closeEl) closeEl.click();
        });
    });
  }

  private isTrackedButton(element: HTMLElement): boolean {
    const font = getComputedStyle(element).fontFamily;
    return (
      font.includes('sp-sign-in-action') || font.includes('sp-sign-up-action')
    );
  }

  private getAuthActionType(element: HTMLElement): 'signIn' | 'signUp' {
    const font = getComputedStyle(element).fontFamily;
    return font.includes('sp-sign-up-action') ? 'signUp' : 'signIn';
  }

  private observePopupClose(): void {
    if (!this.popupElement) return;

    const popupObserver = new MutationObserver(() => {
      if (getComputedStyle(this.popupElement!).display === 'none') {
        this.detachClickListeners();
        popupObserver.disconnect();
      }
    });

    popupObserver.observe(this.popupElement, {
      attributes: true,
      attributeFilter: ['style', 'class'],
    });
  }

  private detachClickListeners(): void {
    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
      this.clickSubscription = null;
    }
  }

  private addPointerToSpecialFonts(popup: HTMLElement) {
    const elements = popup.querySelectorAll('*');
    elements.forEach((element: HTMLElement) => {
      if (this.isTrackedButton(element)) {
        element.style.cursor = 'pointer';
      }
    });
  }
}
