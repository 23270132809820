@if (canRender) {
    @switch (domainValue) {
        @default {
            <footer class="footer">
                <div class="footer__top">
                    <div class="footer__menus">
                        <div class="footer-home-view">
                            <h3 class="footer-home-view__text">{{ 'FOOTER.HOME_PAGE_TEXT' | translate }}</h3>
                            <p class="footer-home-view__subtitle">{{ 'FOOTER.HOME_PAGE_SUBTITLE' | translate }}</p>
                            <div class="footer-home-view__btn-action">
                                <button class="btn-default btn-primary start-planing-btn"
                                        (click)="navigateToTripPlanner()">
                                    {{ 'FOOTER.START_PLANING' | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="menu-col">
                            <h4 class="menu-header">{{ destinationMenu.label }}</h4>
                            <ul class="menu-list col-2">
                                @for (item of destinationMenu.items; track $index; ) {
                                    <li class="menu-item">
                                        <a (click)="$event.preventDefault(); navigateMenuItem(item)"
                                           [attr.href]="item.url"
                                           [target]="item.target">
                                            {{ item.label }}
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="footer__bottom">
                    <ul class="menu-list">
                        @for (item of companyMenu.items; let last = $last; track $index) {
                            <li class="menu-item" [class.menu-item--col]="last && isClientFromIsrael">
                                <a (click)="$event.preventDefault(); navigateMenuItem(item)"
                                   [attr.href]="item.url"
                                   [target]="item.target">
                                    {{ item.label }}
                                </a>
                                @if (last && isClientFromIsrael) {
                                    <a (click)="$event.preventDefault(); navigateMenuItem({label: '', url: 'terms-n-conditions/he', target: eUrlTarget.SELF})"
                                       [attr.href]="'terms-n-conditions/he'"
                                       target="_self">
                                        תנאי שימוש
                                    </a>
                                }
                            </li>
                        }
                    </ul>
                </div>
                <div class="footer__created">
                    © RoutePerfect 2014-{{ today | date: 'YYYY' }}
                </div>
            </footer>
        }
        @case (eDomainValue.LITHUANIA) {
            <app-footer-lt></app-footer-lt>
        }
    }
}
