import { Injectable } from '@angular/core';

import { IconService } from './icon.service';

@Injectable()
export class InitService {
  constructor(private iconService: IconService) {}

  initIcons(): Promise<boolean> {
    return new Promise((resolve) => {
      this.iconService.registerIcons();
      resolve(true);
    });
  }
}
