import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { IWhitelabel } from '../interfaces/whitelabel';
import { DomainTrackingService } from '../services/domain-tracking.service';

export const whitelabelInterceptor: HttpInterceptorFn = (req, next) => {
  const domainTrackingService = inject(DomainTrackingService);
  const whitelabel: IWhitelabel | null = domainTrackingService.whitelabel;

  if (!isLocalUrl(req.url)) {
    return next(req);
  }

  if (req.url.includes('lookup.hotels') || !whitelabel) {
    return next(req);
  }

  return next(addWhitelabelHeader(req, whitelabel));
};

function addWhitelabelHeader(
  request: HttpRequest<any>,
  whitelabel: IWhitelabel,
): HttpRequest<any> {
  return request.clone({
    setHeaders: {
      whitelabel: whitelabel.value,
    },
  });
}

function isLocalUrl(url: string): boolean {
  return url.charAt(0) === '.' || url.includes('routeperfect.com');
}
