import { Component, EventEmitter, Input, Output } from '@angular/core';

import { sideSlideAnimation } from 'src/app/shared/animations/side-slide.animation';

import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-mobile-menu-lt',
    templateUrl: './mobile-menu-lt.component.html',
    styleUrls: ['./mobile-menu-lt.component.scss'],
    animations: [sideSlideAnimation],
    standalone: false
})
export class MobileMenuLtComponent {
  @Input() mobileMenuOpen: boolean;
  @Output() handleMobileMenu: EventEmitter<boolean> = new EventEmitter();

  readonly WHY_LITHUANIA = {
    label: 'Why Lithuania',
    url: '',
    subLinks: [
      {
        icon: 'ic-waves.svg',
        label: 'By the Baltic Sea',
        link: 'https://www.lithuania.travel/en/why-lithuania/by-the-baltic-sea',
      },
      {
        icon: 'ic-sunny-s.svg',
        label: 'The country of 4 seasons',
        link: 'https://www.lithuania.travel/en/why-lithuania/country-of-4-seasons',
      },
      {
        icon: 'ic-texture.svg',
        label: 'Culture and heritage',
        link: 'https://www.lithuania.travel/en/why-lithuania/culture-and-heritage',
      },
      {
        icon: 'ic-lightblub.svg',
        label: 'Ideas and innovations',
        link: 'https://www.lithuania.travel/en/why-lithuania/ideas-and-innovations',
      },
      {
        icon: 'ic-check-c.svg',
        label: 'Where to visit?',
        link: 'https://www.lithuania.travel/en/where-to-visit',
      },
    ],
  };

  readonly REGIONS_LITHUANIA = {
    label: 'Where to go?',
    url: '',
    subLinks: [
      {
        icon: 'ic-city.svg',
        label: 'Major cities',
        link: 'https://www.lithuania.travel/en/where-to-visit/major-cities',
      },
      {
        icon: 'ic-nature.svg',
        label: 'Nature',
        link: 'https://www.lithuania.travel/en/where-to-visit/nature',
      },
      {
        icon: 'ic-diamond.svg',
        label: 'Undiscovered gems',
        link: 'https://www.lithuania.travel/en/where-to-visit/undiscovered-gems',
      },
      {
        icon: 'ic-sunny-s.svg',
        label: 'Seasonal highlights',
        link: 'https://www.lithuania.travel/en/where-to-visit/seasonal-highlights',
      },
    ],
  };

  readonly WHAT_TO_DO_LITHUANIA = {
    label: 'What to do?',
    url: '',
    subLinks: [
      {
        icon: 'ic-landscape.svg',
        label: 'Active recreation',
        link: 'https://www.lithuania.travel/en/what-to-do/active-recreation',
      },
      {
        icon: 'ic-fastfood.svg',
        label: 'Food & Drink',
        link: 'https://www.lithuania.travel/en/what-to-do/food-and-drink',
      },
      {
        icon: 'ic-image.svg',
        label: 'History, culture, art',
        link: 'https://www.lithuania.travel/en/what-to-do/history-culture-art',
      },
      {
        icon: 'ic-park.svg',
        label: 'Ecotourism',
        link: 'https://www.lithuania.travel/en/what-to-do/ecotourism',
      },
    ],
  };

  constructor(public authService: AuthService) {}
}
