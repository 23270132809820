import { NgModule } from '@angular/core';
import {
  AsyncPipe,
  DatePipe,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { MatIcon } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { SocialLoginModule } from '@abacritt/angularx-social-login';

import { GetNamePipe } from '../pipes/get-name.pipe';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FooterLtComponent } from './lithuanian/footer-lt/footer-lt.component';
import { HeaderLtComponent } from './lithuanian/header-lt/header-lt.component';
import { MobileMenuComponent } from './header/mobile-menu/mobile-menu.component';
import { MobileMenuLtComponent } from './lithuanian/header-lt/mobile-menu-lt/mobile-menu-lt.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MobileMenuComponent,
    FooterComponent,
    FooterLtComponent,
    HeaderLtComponent,
    MobileMenuLtComponent,
  ],
  imports: [
    SocialLoginModule,
    NgOptimizedImage,
    GetNamePipe,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    AsyncPipe,
    DatePipe,
    NgTemplateOutlet,
    MatIcon,
  ],
  exports: [FooterComponent, HeaderComponent],
})
export class LandingModule {}
