import { IFooterMenu } from '../interfaces/footer';

import { ERoutes } from '../../core/enums/routes.enum';
import { EUrlTarget } from '../../core/enums/url-target.enum';

export const COMPANY_MENU: IFooterMenu = {
  label: '',
  url: ERoutes.ABOUT_US,
  target: EUrlTarget.SELF,
  items: [
    {
      label: 'About Us',
      url: ERoutes.ABOUT_US,
      target: EUrlTarget.SELF,
    },
    {
      label: 'Testimonials',
      url: ERoutes.TRIP_TESTIMONIALS,
      target: EUrlTarget.SELF,
    },
    {
      label: 'Blog',
      url: 'https://www.routeperfect.com/blog',
      target: EUrlTarget.BLANK,
    },
    {
      label: 'Trip planner',
      url: ERoutes.TRIP_PLANNER,
      target: EUrlTarget.SELF,
    },
    {
      label: 'Countries',
      url: ERoutes.COUNTRIES,
      target: EUrlTarget.SELF,
    },
    {
      label: 'Accessibility Declaration',
      url: ERoutes.ACCESSIBILITY_DECLARATION,
      target: EUrlTarget.SELF,
    },
    {
      label: 'Terms and conditions',
      url: ERoutes.TERMS_N_CONDITIONS,
      target: EUrlTarget.SELF,
    },
  ],
};

export const DESTINATIONS_MENU: IFooterMenu = {
  label: 'Popular destinations',
  url: 'https://www.routeperfect.com/blog/10-most-romantic-small-towns-in-italy-as-chosen-by-our-routeperfect-travelers/',
  target: EUrlTarget.BLANK,
  items: [
    {
      label: 'Italy',
      url: 'italy',
      target: EUrlTarget.SELF,
    },
    {
      label: 'Spain ',
      url: 'spain',
      target: EUrlTarget.SELF,
    },
    {
      label: 'Japan ',
      url: 'japan',
      target: EUrlTarget.SELF,
    },
    {
      label: 'Germany',
      url: 'germany',
      target: EUrlTarget.SELF,
    },
    {
      label: 'France',
      url: 'france',
      target: EUrlTarget.SELF,
    },
    {
      label: 'United Kingdom ',
      url: 'united-kingdom',
      target: EUrlTarget.SELF,
    },
    {
      label: 'Czech Republic',
      url: 'czech-republic',
      target: EUrlTarget.SELF,
    },
    {
      label: 'Norway ',
      url: 'norway',
      target: EUrlTarget.SELF,
    },
  ],
};
