import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection,
  TransferState,
} from '@angular/core';
import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DatePipe, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import {
  provideClientHydration,
  withEventReplay,
  withI18nSupport,
} from '@angular/platform-browser';

import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';

import { NgxDaterangepickerMd } from '@sanmanto-sds/ngx-daterangepicker-material';

import {
  GoogleInitOptions,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';

import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader';

import { routes } from './app.routes';
import { SocialConfig } from './core/configs/social-config';

import { InitService } from './core/services/init.service';
import { IconService } from './core/services/icon.service';

import { authInterceptor } from './core/interceptors/user.interceptor';
import { errorInterceptor } from './core/interceptors/error.interceptor';
import { whitelabelInterceptor } from './core/interceptors/whitelabel.interceptor';

function initIcons(service: InitService) {
  return () => service.initIcons();
}

const googleLoginOptions: GoogleInitOptions = {
  scopes: 'profile email',
  oneTapEnabled: false,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(withI18nSupport()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        whitelabelInterceptor,
        authInterceptor,
        errorInterceptor,
      ]),
    ),
    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    provideAnimations(),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return config.src;
      },
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              SocialConfig.googleAppId,
              googleLoginOptions,
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    DatePipe,
    InitService,
    IconService,
    provideAppInitializer(() => {
      const initializerFn = initIcons(inject(InitService));
      return initializerFn();
    }),
    importProvidersFrom([
      NgxDaterangepickerMd.forRoot(
        {
          monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
        },
        {
          cellBorderRadius: '50%',
        },
      ),
    ]),
  ],
};
