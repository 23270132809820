import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogClose } from '@angular/material/dialog';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';

import { RecaptchaModule } from 'ng-recaptcha-2';

import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { SafeResourceUrlPipe } from '../pipes/safe-resource-url.pipe';

import { SharedDialogComponent } from './shared-dialog/shared-dialog.component';
import { DbdBlogDialogComponent } from './dbd-blog-dialog/dbd-blog-dialog.component';

@NgModule({
  declarations: [SharedDialogComponent, DbdBlogDialogComponent],
  imports: [
    RecaptchaModule,
    MatCheckbox,
    CdkCopyToClipboard,
    ReactiveFormsModule,
    SafeResourceUrlPipe,
    SafeUrlPipe,
    MatDialogClose,
    MatIcon,
  ],
  exports: [SharedDialogComponent, DbdBlogDialogComponent],
})
export class DialogModule {}
