import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '../../core/services/auth.service';
import { BreakpointService } from '../../core/services/breakpoint.service';

import {
  GoogleAnalyticService,
  HeaderDesktopEvent,
} from '../../core/services/google-analytic.service';

import {
  AuthDialogComponent,
  EAuthPage,
} from '../dialogs/auth-dialog/auth-dialog.component';

@Injectable()
export abstract class AuthAction {
  protected googleAnalyticService = inject(GoogleAnalyticService);
  protected breakpointService = inject(BreakpointService);
  protected authService = inject(AuthService);
  protected router = inject(Router);
  protected dialog = inject(MatDialog);

  openAuthDialog(): void {
    this.setHeaderAnalytic('login');
    this.dialog.open(AuthDialogComponent, {
      width: '1150px',
      maxWidth: '98vw',
      maxHeight: '98dvh',
      panelClass: 'mobile-container',
    });
  }

  openSignUpAuthDialog() {
    this.setHeaderAnalytic('signup');
    this.dialog.open(AuthDialogComponent, {
      width: '1150px',
      maxWidth: '98vw',
      maxHeight: '98dvh',
      panelClass: 'mobile-container',
      data: {
        pageType: EAuthPage.SIGN_UP,
      },
    });
  }

  setHeaderAnalytic(event: HeaderDesktopEvent): void {
    if (this.breakpointService.isMobile()) {
      this.googleAnalyticService.setHeaderMobileAnalytic({
        header_click: event,
        userID: this.authService.userId,
      });
    } else {
      this.googleAnalyticService.setHeaderDesktopAnalytic({
        header_click: event,
        userID: this.authService.userId,
      });
    }
  }
}
