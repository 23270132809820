@switch (domainValue) {
    @default {
        <div class="site-menu" [class.site-menu--m-open]="mobileMenuOpen">
            <div class="container wide-container">
                <div class="site-menu__inner">
                    @if (breakpointService.isMobile()) {
                        <div class="site-menu__section">
                            <button class="hamburger"
                                    [class.hamburger--open]="mobileMenuOpen"
                                    [class.hamburger--back]="headerStateService.backBtnPresent()"
                                    (click)="toggleBtnHandler()">
                                <span class="hamburger__bar"></span>
                                <span class="hamburger__bar"></span>
                                <span class="hamburger__bar"></span>
                            </button>
                        </div>
                        <div class="site-menu__section" [class.site-menu__section--hidden]="mobileMenuOpen">
                            @if (previewTripConfig.showLogo) {
                                <a [routerLink]="['./']" class="site-menu__logo"
                                   (click)="setHeaderAnalytic('logo')">
                                    <img [src]="mobileLogo" class="site-menu__logo__image" width="160" height="42" alt="RoutePerfect">
                                </a>
                            } @else {
                                <div class="preview-box" (click)="openPlanningFormDialog()">
                                    <p class="preview-box__location">
                                        {{ previewTripConfig.startInLocation + ' - ' + previewTripConfig.endInLocation }}
                                        <img ngSrc="assets/used-icons/ic-edit.svg" width="24" height="24" alt="">
                                    </p>
                                    <div class="preview-box__additional">
                                        {{ previewTripConfig.duration }}
                                        <img ngSrc="assets/used-icons/ic-people.svg" width="16" height="16" alt="">
                                        {{ previewTripConfig.travelers }}
                                    </div>
                                </div>
                            }
                        </div>
                    } @else {
                        <div class="site-menu__section">
                            <a [routerLink]="['./']" class="site-menu__logo" (click)="setHeaderAnalytic('logo')">
                                <img [src]="mainLogo" class="site-menu__logo__image" width="160" height="46" alt="RoutePerfect">
                            </a>
                        </div>
                        <div class="site-menu__section site-menu__section--full">
                            <ng-content></ng-content>
                            <div class="nav-items">
                                <div class="app-links">
                                    <p class="app-links__link uppercase" (click)="goToTripPlannerPage()">
                                        Plan a trip
                                    </p>
                                    <div class="separator"></div>
                                </div>
                            </div>
                        </div>
                        @if (isLogged) {
                            <div class="site-menu__section">
                                <div class="nav-items">
                                    <div class="app-links">
                                        <div class="app-links__link" routerLink="account/saved-trips"
                                             (click)="setHeaderAnalytic('saved trips')">
                                            @if (savedTrips$ | async; as tripAmount) {
                                                <span class="trips--icon">{{ tripAmount }}</span>
                                            }
                                            Saved Trips
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    }

                    <div class="site-menu__section site-menu__section--stretch" [class.site-menu__section--hidden]="mobileMenuOpen">
                        <div class="site-menu__user">
                            @if (isLogged) {
                                @if (!breakpointService.isMobile()) {
                                    <div class="site-menu__user__placeholder">
                                        {{ userProfile?.name?.charAt(0) }}
                                    </div>
                                    <ul class="site-menu__user__dropdown show-for-medium">
                                        @for (loggedMenuItem of loggedMenuItems; track $index) {
                                            <li>
                                                <a [routerLink]="[loggedMenuItem.route]"
                                                   [routerLinkActiveOptions]="{exact: true}"
                                                   (click)="setRouteAnalytic(loggedMenuItem.route)"
                                                   routerLinkActive="active" class="site-menu__user__link">
                                                    <span>{{ loggedMenuItem.title }}</span>
                                                </a>
                                            </li>
                                        }
                                        <li>
                                            <button type="button"
                                                    class="site-menu__user__link site-menu__user__link--logout"
                                                    (click)="logStatus(false)">
                                                Sign out
                                            </button>
                                        </li>
                                    </ul>
                                } @else {
                                    <div class="site-menu__user__placeholder"
                                         [class.site-menu__user__placeholder--hidden]="mobileMenuOpen"
                                         [routerLink]="[eRoutes.SLASH, eRoutes.ACCOUNT, eRoutes.MY_TRIPS]">
                                        <span>{{ userProfile?.name?.charAt(0) }}</span>
                                      @if (savedTrips$ | async; as tripAmount) {
                                        <span class="site-menu__user__placeholder--count">
                                          {{tripAmount}}
                                        </span>
                                      }
                                    </div>
                                }
                            } @else {
                                @if (!breakpointService.isMobile()) {
                                    <div class="site-menu__desktop">
                                        <button class="btn-default login login__underline" (click)="openAuthDialog()">
                                            Log In
                                        </button>
                                        <button class="btn-default signup" (click)="openSignUpAuthDialog()">
                                            {{ 'ACTIONS.SIGN_UP' | translate }}
                                        </button>
                                    </div>
                                } @else {
                                    <button class="site-menu__mobile"
                                            [class.site-menu__mobile--small]="!previewTripConfig.showLogo"
                                            (click)="openAuthDialog()">
                                        <mat-icon [svgIcon]="eSvgIcons.USER"></mat-icon>
                                        <span>Sign In</span>
                                    </button>
                                }
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-mobile-menu
                [mobileMenuOpen]="mobileMenuOpen"
                [previewTripConfig]="previewTripConfig"
                (handleMobileMenu)="toggleMobileMenu()"
        ></app-mobile-menu>
    }
    @case (eDomainValue.LITHUANIA) {
        <app-header-lt [isLogged]="isLogged" [mobileMenuOpen]="mobileMenuOpen"></app-header-lt>
    }
}
