import { Injectable } from '@angular/core';

import Radar from 'radar-sdk-js';

import { RadarConfig } from '../configs/config';

@Injectable({
  providedIn: 'root',
})
export class RadarService {
  initializeRadar() {
    Radar.initialize(RadarConfig.radarLiveKey);
  }
}
