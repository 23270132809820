@if (mobileMenuOpen) {
    <div class="mobile-menu-container--open" [@menuSlideIn]>
        <div class="mobile-menu-container__wrapper">
            <div class="mobile-menu-container__scrollable-box">
                <ul class="mobile-menu-container__wrapper__list">
                    @for (menuItem of mainMenuItems; track $index) {
                        <li>
                            <a class="mobile-menu-container__wrapper__list-item"
                               [target]="menuItem.target"
                               (click)="redirectTo(menuItem.route, menuItem.target, menuItem.title, $event)">
                                <img [ngSrc]="'assets/used-icons/' + menuItem.icon" width="24" height="24" alt="">
                                {{ menuItem.title }}
                            </a>
                        </li>
                    }
                    @if (previewTripConfig && !previewTripConfig.showLogo) {
                        <li (click)="redirectTo('./trip-planner', eUrlTarget.SELF, 'Plan a trip', $event)">
                            <div class="mobile-menu-container__wrapper__active-plan">
                                <p class="plan-text">My active plan</p>
                                <p class="plan-name">{{ previewTripConfig.activePlan }}</p>
                            </div>
                        </li>
                        <li>
                            <p class="mobile-menu-container__wrapper__list-item"
                               (click)="goToPlannerService([eRoutes.SLASH, eRoutes.TRIP_PLANNER], eCityProvider.FLIGHTS)">
                                <img ngSrc="assets/used-icons/ic-plane.svg" width="24" height="24" alt="">
                                Flights
                            </p>
                        </li>
                        <li>
                            <p class="mobile-menu-container__wrapper__list-item"
                               (click)="goToPlannerService([eRoutes.SLASH, eRoutes.TRIP_PLANNER], eCityProvider.CARRENTAL)">
                                <img ngSrc="assets/used-icons/ic-car.svg" width="24" height="24" alt="">
                                Cars
                            </p>
                        </li>
                        <li>
                            <p class="mobile-menu-container__wrapper__list-item"
                               (click)="goToPlannerService([eRoutes.SLASH, eRoutes.TRIP_PLANNER], eCityProvider.BOOKING)">
                                <img ngSrc="assets/svg/ic-m-bed.svg" width="24" height="24" alt="">
                                Hotels
                            </p>
                        </li>
                        <li>
                            <p class="mobile-menu-container__wrapper__list-item"
                               (click)="goToPlannerService([eRoutes.SLASH, eRoutes.TRIP_PLANNER], eCityProvider.GENIUS)">
                                <img ngSrc="assets/used-icons/ic-hiking.svg" width="24" height="24" alt="">
                                City Services
                            </p>
                        </li>
                    }
                </ul>
                <ul class="mobile-menu-container__wrapper__list">
                    @for (menuItem of additionalMenuItems; track $index) {
                        <li>
                            <a class="mobile-menu-container__wrapper__list-item"
                               [routerLink]="[menuItem.route]"
                               [target]="menuItem.target"
                               (click)="redirectTo(menuItem.route, menuItem.target, menuItem.title, $event)">
                                {{ menuItem.title }}
                            </a>
                        </li>
                    }
                </ul>
                <ul class="mobile-menu-container__wrapper__list">
                    <li>
                        <a class="mobile-menu-container__wrapper__list-item"
                           [routerLink]="[eRoutes.SLASH, eRoutes.TERMS_N_CONDITIONS]"
                           (click)="closeMobileMenu()">
                            Terms and Conditions
                        </a>
                    </li>
                    @if (isClientFromIsrael) {
                        <li>
                            <a class="mobile-menu-container__wrapper__list-item"
                                [routerLink]="[eRoutes.SLASH, eRoutes.TERMS_N_CONDITIONS, 'he']"
                                (click)="closeMobileMenu()">
                               תנאי שימוש
                            </a>
                        </li>
                    }
                    <li>
                        <a class="mobile-menu-container__wrapper__list-item"
                           [routerLink]="[eRoutes.SLASH, eRoutes.ACCESSIBILITY_DECLARATION]"
                           (click)="closeMobileMenu()">
                            Accessibility Declaration
                        </a>
                    </li>
                </ul>
            </div>
            <div class="mobile-menu-container__wrapper__footer">
                <p>© RoutePerfect 2014-{{ today | date: 'YYYY' }}</p>
            </div>
        </div>
    </div>
}
