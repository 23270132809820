import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { ESvgIcons } from '../enums/svg-icons';

@Injectable()
export class IconService {
  private readonly icons: string[] = Object.values(ESvgIcons);
  private readonly PATH: string = 'assets/svg';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  public registerIcons(): void {
    this.icons.forEach((key: any) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `${this.PATH}/${key}.svg`,
        ),
      );
    });
  }
}
