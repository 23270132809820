import { Component, ViewEncapsulation } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-footer-lt',
    templateUrl: './footer-lt.component.html',
    styleUrls: ['./footer-lt.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FooterLtComponent {
  public readonly routePerfectLogo: string =
    environment.cdnUrl + 'app/images/routeperfect_logo.png';
  public readonly breakpoints = this.device;

  constructor(private device: DeviceDetectorService) {}
}
